<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons">
        <router-link class="btn" :to="{ name: 'admin.appointments.overview' }">{{ $t('Overzicht') }}</router-link>
        <button @click="openModal(null)" class="btn btn-add" v-if="checkPermissions(['appointment.create'])">{{ $t('Afspraak toevoegen') }}</button>
      </div>
    </header>

    <div class="main-content appointments-overview">
      <div class="pane">
        <div class="week-wrapper">
          <div class="week">
            <div class="controls" v-if="!isLoading">
              <button @click="prevWeek" class="btn-prev"></button>
              <button @click="nextWeek" class="btn-next"></button>
              <div class="week-label" v-if="startDate">
                {{ startDate.setLocale('nl').toFormat('dd LLLL yyyy') }} - {{ endDate.setLocale('nl').toFormat('dd LLLL yyyy') }}
              </div>
              <img src="@/assets/img/calendar.svg" alt="Calendar">
            </div>

            <div :key="i" class="day-wrapper" v-for="(day, i) in days">
              <div class="day" @contextmenu.prevent.stop="dayRightClick($event, day.date)">
                <span class="weekday">{{ day.date.setLocale('nl').toFormat('EEE') }}</span>
                <span class="daynumber">{{ day.date.setLocale('nl').toFormat('dd') }}</span>
              </div>

              <div class="timeslots" v-if="day.slots.length > 0">
                <div :key="slot.id" @contextmenu.prevent.stop="slotRightClick($event, slot)" @click="setSelectedSlot(slot)"
                     class="timeslot"
                     :class="{
                                    'unavailable': !slot.available,
                                    'active': selectedSlot != null && selectedSlot.time.ts === slot.time.ts,
                                    'slot-full': slot.booked >= slot.total,
                                    'slot-empty': slot.booked === 0,
                                    'slot-available': slot.booked > 0 && slot.booked < slot.total,
                                  }"
                     v-for="slot in day.slots">
                  <span class="amount">{{ slot.total }}</span>
                  <span>{{ slot.time.setLocale('nl').toFormat('HH:mm') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="appointments-info" v-if="appointments.length > 0">
            <div :key="appointment.id" class="appointment" v-for="appointment in appointments">
              <button @click="appointments = []" class="btn-close">
                <img src="@/assets/img/close.svg" alt="Close">
              </button>
              <div class="name">{{ appointment.first_name + ' ' + appointment.last_name }}</div>

              <div class="label-value-wrapper date">
                <span class="label">Datum:</span>
                <span class="value">{{ appointment.slot.setLocale('nl').toFormat('dd LLLL yyyy') }}</span>
                <span><img src="@/assets/img/calendar_black.svg" alt="Calendar"></span>
              </div>

              <div class="label-value-wrapper time">
                <span class="label">Uur:</span>
                <span class="value">{{ appointment.slot.setLocale('nl').toFormat('T') }}</span>
                <img src="@/assets/img/clock.svg" alt="Clock">
              </div>

              <div class="label-value-wrapper date">
                <span class="label">Aangemaakt:</span>
                <span class="value">{{ appointment.created.setLocale('nl').toFormat('dd LLLL yyyy HH:mm') }}</span>
                <span><img src="@/assets/img/calendar_black.svg" alt="Calendar"></span>
              </div>

              <h3>Extra info:</h3>

              <div class="grid">
                <div class="label-value-wrapper">
                  <span class="label">Type project:</span>
                  <span class="value">{{ projectTypeOptions[appointment.project_type] }}</span>
                </div>
                <div class="label-value-wrapper">
                  <span class="label">Op zoek naar:</span> <span class="value">
                    <span :key="i" v-for="(label, i) in appointment.searching_for">
                        {{ searchingForOptions[label] }} {{ (appointment.searching_for.length > (i + 1)) ? ',' : '' }}
                    </span>
                  </span>
                </div>
                <div class="label-value-wrapper">
                  <span class="label">Nieuwe ramen:</span>
                  <span class="value">{{ newWindowsOptions[appointment.new_windows] }}</span>
                </div>
                <div class="label-value-wrapper">
                  <span class="label">Geschatte periode:</span>
                  <span class="value">{{ deliveryTermOptions[appointment.delivery_term] }}</span>
                </div>
              </div>

              <div class="label-value-wrapper extra">
                <span class="label">Bijkomende opmerkingen of vragen:</span>
                <span class="value">{{ appointment.extra || '/' }}</span>
              </div>

              <div class="label-value-wrapper" v-if="appointment.files && appointment.files.length > 0">
                <span class="label">Bouwplannen:</span>
                <div :key="file" class="value" v-for="file in appointment.files">
                  <a target="_blank" v-if="images[file]" :href="images[file].url">{{ images[file].name }}</a>
                </div>
              </div>

              <div class="label-value-wrapper">
                <span class="label">Spontaan:</span>
                <span class="value">{{ appointment.spontaneous ? 'Ja' : 'Nee' }}</span>
              </div>

              <button v-if="checkPermissions(['appointment.edit'])" @click="openModal(appointment)" class="btn btn-inverse">Wijzig afspraak</button>
              <button v-if="checkPermissions(['appointment.delete'])" @click="deleteAppointment(appointment.id)" class="btn btn-inverse">Verwijder afspraak</button>
              <router-link target="_blank" class="btn" v-if="appointment.leads.id" :to="{ name: 'ww.leads.detail', params:{ id: appointment.leads.id, locale: 'nl' }}">Lead bekijken</router-link>
            </div>

            <div class="buttons">
              <button v-if="checkPermissions(['appointment.create'])" @click="openModal(null)" class="btn">Afspraak toevoegen</button>
            </div>
          </div>
        </div>

        <Appointment @save="appointmentSaved" @close="open = false" :open="open" :appointment="activeAppointment" :selected-date-data="selectedDate" :selected-date-time-data="selectedDateTime" :selected-slot-data="selectedSlot"></Appointment>
      </div>
    </div>

    <vue-simple-context-menu element-id="slotContextMenu" :options="slotContextMenuOptions" ref="slotContextMenu" @option-clicked="slotContextOptionClicked"></vue-simple-context-menu>
    <vue-simple-context-menu element-id="dayContextMenu" :options="dayContextMenuOptions" ref="dayContextMenu" @option-clicked="dayContextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { ApiService } from '@/services/admin/api.service'
import Swal from 'sweetalert2'
import appointmentsData from '@/appointments-data'
import Appointment from '@/components/admin/Appointment'
import PageTitle from '@/components/admin/PageTitle'

export default {
  mixins: [appointmentsData],
  components: {
    PageTitle,
    Appointment
  },
  data () {
    return {
      activeAppointment: null,
      open: false,
      appointments: [],
      pages: [],
      isLoading: false,
      startDate: null,
      endDate: null,
      formValues: {},
      selectedDate: null,
      selectedDateTime: null,
      selectedSlot: null,
      // selectedPickerSlot: null,
      slots: {},
      firstSlot: null,
      lastSlot: null,
      days: {},
      defaultSlotContextMenuOptions: [
        { id: 'addSlotConfig', name: 'Slot toevoegen' },
        { id: 'deleteSlotConfig', name: 'Slot verwijderen' },
        { id: 'blockSlotConfig', name: 'Slot blokkeren' }
      ],
      defaultDayContextMenuOptions: [
        { id: 'blockSlotConfig', name: 'Slots blokkeren' },
        { id: 'unblockSlotConfig', name: 'Slots deblokkeren' }
      ],
      slotContextMenuOptions: [],
      dayContextMenuOptions: []
    }
  },
  async mounted () {
    this.isLoading = true

    this.startDate = DateTime.now().startOf('week')
    this.endDate = this.startDate.endOf('week')

    this.slotContextMenuOptions = this.defaultSlotContextMenuOptions

    this.days = await this.fetchReservedSlots(this.startDate.toISODate(), this.endDate.toISODate())
    this.isLoading = false
  },
  methods: {
    async appointmentSaved () {
      this.days = await this.fetchReservedSlots(this.startDate.toISODate(), this.endDate.toISODate())
      await this.fetchAppointments()
    },
    openModal (appointment) {
      this.activeAppointment = appointment || null
      this.open = true
    },
    dayRightClick: async function (event, date) {
      this.dayContextMenuOptions = this.defaultDayContextMenuOptions
      this.$refs.dayContextMenu.showMenu(event, date)
    },
    slotRightClick: async function (event, slot) {
      if (!(this.checkPermissions(['appointment.edit']) || this.checkPermissions(['appointment.create']))) {
        return false
      }

      this.slotContextMenuOptions = JSON.parse(JSON.stringify(this.defaultSlotContextMenuOptions))

      const response = await ApiService.fetchSlotConfig(slot.time.toISO({ includeOffset: false }))

      slot.types = {}
      slot.customSlots = response.data

      slot.customSlots.map((key) => {
        if (slot.types[key.type] === undefined) {
          slot.types[key.type] = []
        }

        slot.types[key.type].push(key)
      })

      if (slot.total > slot.booked && slot.booked > 0) {
        this.slotContextMenuOptions.splice(2, 1)
      }

      if (slot.total === slot.booked) {
        this.slotContextMenuOptions = [
          { id: 'addSlotConfig', name: 'Slot toevoegen' }
        ]
      }

      if (slot.types[2]) {
        this.slotContextMenuOptions = [
          { id: 'blockSlotConfig', name: 'Slot deblokkeren' }
        ]
      }

      this.$refs.slotContextMenu.showMenu(event, slot)
    },
    addSlotConfig: async function (slot, type) {
      await ApiService.addSlotConfig(slot.toISO({ includeOffset: false }), type)
    },
    deleteSlotConfig: async function (slotId) {
      await ApiService.deleteSlotConfig(slotId)
    },
    slotContextOptionClicked: async function (event) {
      const slot = event.item
      const action = event.option.id

      // 0 = Add
      // 1 = Remove
      // 2 = Block
      let typeToDelete = null
      let typeToAdd = null

      if (action === 'addSlotConfig') {
        typeToDelete = 1
        typeToAdd = 0
      } else if (action === 'deleteSlotConfig') {
        typeToDelete = 0
        typeToAdd = 1
      } else if (action === 'blockSlotConfig') {
        typeToDelete = 2
        typeToAdd = 2
      }

      if (slot.types[typeToDelete]) {
        await this.deleteSlotConfig(slot.types[typeToDelete][0].id)
      } else {
        await this.addSlotConfig(slot.time, typeToAdd)
      }

      this.days = await this.fetchReservedSlots(this.startDate.toISODate(), this.endDate.toISODate())
    },
    dayContextOptionClicked: async function (event) {
      const date = event.item
      const action = event.option.id

      let method = 'post'
      let type = null

      if (action === 'addSlotConfig') {
        type = 0
      } else if (action === 'deleteSlotConfig') {
        type = 1
      } else if (action === 'blockSlotConfig') {
        type = 2
      } else if (action === 'unblockSlotConfig') {
        method = 'delete'
        type = 2
      }

      await ApiService.daySlotConfig(date.toISODate({ includeOffset: false }), type, method)

      this.days = await this.fetchReservedSlots(this.startDate.toISODate(), this.endDate.toISODate())
    },
    prevWeek: async function () {
      this.startDate = this.startDate.minus({ weeks: 1 })
      this.endDate = this.startDate.endOf('week')

      this.days = await this.fetchReservedSlots(this.startDate.toISODate(), this.endDate.toISODate())
    },
    nextWeek: async function () {
      this.startDate = this.startDate.plus({ weeks: 1 })
      this.endDate = this.startDate.endOf('week')

      this.days = await this.fetchReservedSlots(this.startDate.toISODate(), this.endDate.toISODate())
    },
    fetchReservedSlots: async function (startDate, endDate) {
      const response = await ApiService.fetchReservedSlots(startDate, endDate)
      const days = {}

      if (response.data.slots !== undefined) {
        const data = response.data.slots

        for (const [day, timeslots] of Object.entries(data)) {
          days[day] = {
            date: DateTime.fromISO(day),
            slots: []
          }

          timeslots.forEach((slot, i) => {
            days[day].slots[i] = {}
            days[day].slots[i].available = slot.available
            days[day].slots[i].time = DateTime.fromISO(slot.time)
            days[day].slots[i].total = slot.total
            days[day].slots[i].booked = slot.booked
          })
        }
      }

      return days
    },
    fetchAppointments: async function () {
      const response = await ApiService.fetchAppointments(this.selectedSlot.time.toISO({ includeOffset: false }))

      const data = response.data
      this.appointments = []
      const files = []

      if (data.length > 0) {
        for (const appointment of data) {
          for (const [key, value] of Object.entries(appointment)) {
            let newVal = value

            if (key === 'slot' || key === 'created') {
              newVal = DateTime.fromISO(value)
            }

            if (key === 'files' && value) {
              for (const id of value) {
                files.push({ file_id: id })
              }
            }

            appointment[key] = newVal
          }

          this.appointments.push(appointment)
        }
      }

      await this.$store.dispatch('fetchImages', { objects: files, value: 'file_id' })
    },
    deleteAppointment: async function (id) {
      if (!this.checkPermissions(['appointment.delete'])) {
        return false
      }

      Swal.fire({
        title: 'Ben je zeker dat je deze afspraak wilt verwijderen?',
        text: 'Deze actie kan niet ongedaan worden gemaakt!',
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: 'Verwijderen',
        cancelButtonText: 'Annuleren'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiService.deleteAppointment(id)

          if (response.status === 200) {
            this.days = await this.fetchReservedSlots(this.startDate.toISODate(), this.endDate.toISODate())
            await this.fetchAppointments()
          }
        }
      })
    },
    /**
     * Handler when clicking on a day in the VCalendar.
     *
     * @param {*} day
     */
    onDayClick: async function (day) {
      this.selectedDate = day.id

      const date = DateTime.fromJSDate(day.date).toISODate()

      const slots = await this.fetchReservedSlots(date, date)
      this.slots = slots[this.selectedDate]
    },
    /**
     * Handler when clicking on a timeslot in the week overview.
     *
     * @param {*} slot
     */
    setSelectedSlot: async function (slot) {
      if (this.selectedSlot === slot && slot.booked > 0 && this.appointments.length > 0) {
        return
      }

      this.selectedSlot = slot
      this.appointments = []
      this.selectedDateTime = ''.concat(this.selectedSlot.time.toISODate(), 'T', this.selectedSlot.time.toISOTime({ includeOffset: false }))
      const e = event

      const day = {
        id: slot.time.toISODate(),
        date: slot.time.toJSDate()
      }
      await this.onDayClick(day)

      if (slot.booked === 0) {
        if (this.checkPermissions(['appointment.create'])) {
          this.openModal(null)
        }
      }

      if (slot.booked > 0) {
        await this.fetchAppointments()

        if (window.innerWidth > 1000) {
          const appointmentWrapper = document.querySelector('.appointments-info')
          appointmentWrapper.style.top = e.target.closest('.day-wrapper').offsetTop + 'px'
        }
      }
    }
  },
  computed: {
    images () {
      return this.$store.getters.getImages
    }
  }
}

</script>
