<template>
  <div>
    <modal @closed="closeModal" class="modal appointments-modal" name="appointmentsAdminModal" :width="770" height="auto" :adaptive="true" :scrollable="true">
      <button @click="closeModal" class="btn-close">
        <img src="@/assets/img/close.svg" alt="Close">
      </button>

      <div class="content">
        <h2>{{ formValues.id ? 'Afspraak wijzigen' : 'Afspraak toevoegen' }}</h2>

        <formulate-form ref="appointmentsForm" name="appointmentsForm" v-if="formValues" v-model="formValues" @submit="addAppointment">
          <h2>{{ $t('Jouw gegevens') }}</h2>
          <div class="personalInformation">
            <formulate-input validation="required" error-behavior="submit" type="text" name="first_name" label="Voornaam"></formulate-input>
            <formulate-input validation="required" error-behavior="submit" type="text" name="last_name" label="Achternaam"></formulate-input>
            <formulate-input validation="required|email" error-behavior="submit" type="email" name="email" :label="$t('E-mail')"></formulate-input>
            <formulate-input validation="required" error-behavior="submit" type="tel" name="telephone" label="Telefoonnummer"></formulate-input>
            <formulate-input validation="required" error-behavior="submit" type="text" name="address1" label="Adres"></formulate-input>
            <formulate-input validation="required" error-behavior="submit" type="text" name="locality" label="Gemeente"></formulate-input>
            <formulate-input validation="required" class="sm" error-behavior="submit" type="text" name="postal_code" label="Postcode"></formulate-input>

            <formulate-input type="checkbox" name="is_same_invoice_address" :value="formValues.is_same_invoice_address" :checked="formValues.is_same_invoice_address" label="Facturatie adres is hetzelfde als leveringsadres"/>
            <div class="personalInformation col-span-2" v-show="!formValues.is_same_invoice_address">
              <formulate-input :validation="!formValues.is_same_invoice_address ? 'required' : ''" error-behavior="submit" type="text" name="invoice_address1" label="Adres"></formulate-input>
              <formulate-input :validation="!formValues.is_same_invoice_address ? 'required' : ''" error-behavior="submit" type="text" name="invoice_locality" label="Gemeente"></formulate-input>
              <formulate-input :validation="!formValues.is_same_invoice_address ? 'required' : ''" class="sm" error-behavior="submit" type="text" name="invoice_postal_code" label="Postcode"></formulate-input>
            </div>

            <div>
              <formulate-input error-behavior="submit" type="checkbox" name="spontaneous" label="Spontaan"></formulate-input>
            </div>
          </div>

          <h2>{{ $t('Datum en tijd kiezen') }}</h2>

          <div class="date-time-wrapper">
            <div class="date-wrapper">
              <v-date-picker is-required title-position="left" v-model="selectedDate" @dayclick="onDayClick"></v-date-picker>
            </div>
            <div class="time-wrapper">
              <h3>{{ $t('Beschikbare uren') }}</h3>
              <h3 v-if="slots == null">{{ $t('Geen uren beschikbaar op deze datum') }}</h3>
              <div class="timeslots" v-if="slots != null">
                <div :key="slot.id" v-on:click="setSelectedPickerSlot(slot)" class="timeslot" :class="{'available': slot.available, 'active': selectedSlot != null && selectedSlot.time.ts === slot.time.ts }" v-for="slot in slots.slots">
                  <span>{{ slot.time.setLocale('nl').toFormat('HH:mm') }}</span>
                </div>
              </div>
            </div>
          </div>

          <h2>{{ $t('Geef je afspraak een startboost') }}</h2>

          <div class="boost">
            <div class="left">
              <formulate-input error-behavior="submit" name="project_type" :options="projectTypeOptions" type="radio" label="Wat voor type project is het?"></formulate-input>
              <formulate-input error-behavior="submit" name="searching_for" :options="searchingForOptions" type="checkbox" label="Ik ben op zoek naar"></formulate-input>
            </div>

            <div class="right">
              <formulate-input error-behavior="submit" name="new_windows" :options="newWindowsOptions" type="radio" label="Worden er ook nieuwe ramen geplaatst?"></formulate-input>
              <formulate-input error-behavior="submit" name="delivery_term" :options="deliveryTermOptions" type="radio" label="Wanneer moeten de werken uitgevoerd worden?"></formulate-input>
            </div>
          </div>

          <div class="files-upload">
            <div class="label">Laad hier je bouwplannen op</div>

            <div class="files" v-if="formValues.files && formValues.files.length > 0">
              <div :key="'file-' + file" class="file" v-for="file in appointment.files">
                <a target="_blank" v-if="images[file]" :href="images[file].url">{{ images[file].name }}</a>
              </div>
            </div>

            <formulate-input add-label="+ Bestand toevoegen" :uploader="uploadFiles" multiple name="files_upload" type="file" upload-behavior="delayed" help="Maximum bestandsgrootte: 30 Mb" validation="mime:application/pdf,image/jpeg,image/png,image/gif"></formulate-input>
          </div>

          <formulate-input error-behavior="submit" name="extra" type="textarea" label="Bijkomende opmerking of vragen?" placeholder="Laat hier eventueel nog een bijkomend bericht achter"></formulate-input>
          <formulate-input v-if="!formValues.id" name="send_email" :checked="true" type="checkbox" label="Bevestigingsmail verzenden"></formulate-input>

          <formulate-input type="submit" :label="$t('Bevestigen')"/>
        </formulate-form>
      </div>
    </modal>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'
import appointmentsData from '@/appointments-data'
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import { DateTime } from 'luxon'

export default {
  props: ['open', 'appointment', 'selectedSlotData', 'selectedDateData', 'selectedDateTimeData'],
  mixins: [appointmentsData],
  components: {
    'v-date-picker': DatePicker
  },
  data () {
    return {
      slots: {},
      days: {},
      formValues: {},
      selectedDate: null,
      selectedSlot: null,
      selectedPickerSlot: null,
      selectedDateTime: null,
      files: {},
      isUploading: false
    }
  },
  methods: {
    async uploadFiles (file, progress, error, option) {
      this.isUploading = true

      const formData = new FormData()
      formData.append('files', file)

      const res = await ApiService.uploadFiles(formData)
      if (res.status === 200) {
        this.isUploading = false

        const fileId = res.data[0]
        // const data = (await ApiService.getFile(fileId)).data
        if (!Array.isArray(this.formValues.files)) {
          this.formValues.files = []
        }
        this.formValues.files.push(fileId)
        // this.formValues.files.push(fileId)
        // this.formValues.files = null
      }
    },
    closeModal: function () {
      this.$modal.hide('appointmentsAdminModal')
      this.$emit('close')
    },
    openModal: async function (appointment = null) {
      if (!(this.checkPermissions(['appointment.edit']) || this.checkPermissions(['appointment.create']))) {
        return false
      }

      this.formValues = {}

      if (appointment && appointment.id) {
        this.formValues.id = appointment.id
        this.formValues.first_name = appointment.first_name
        this.formValues.last_name = appointment.last_name
        this.formValues.spontaneous = appointment.spontaneous
        this.formValues.email = appointment.email
        this.formValues.telephone = appointment.telephone
        this.formValues.postal_code = appointment.postal_code
        this.formValues.project_type = appointment.project_type
        this.formValues.searching_for = appointment.searching_for
        this.formValues.new_windows = appointment.new_windows
        this.formValues.delivery_term = appointment.delivery_term
        this.formValues.files = appointment.files
        this.formValues.extra = appointment.extra
        this.formValues.address1 = appointment.address1
        this.formValues.locality = appointment.locality
        this.formValues.is_same_invoice_address = appointment.is_same_invoice_address
        this.formValues.invoice_address1 = appointment.invoice_address1
        this.formValues.invoice_locality = appointment.invoice_locality
        this.formValues.invoice_postal_code = appointment.invoice_postal_code

        this.selectedSlot = { time: DateTime.fromISO(appointment.slot) }
        this.selectedDate = this.selectedSlot.time.toISODate()
        this.selectedDateTime = this.selectedSlot.time.toISO({ includeOffset: false })
      } else {
        this.formValues.is_same_invoice_address = true
        this.selectedSlot = this.selectedSlotData
        this.selectedDate = this.selectedDateData
        this.selectedDateTime = this.selectedDateTimeData
      }

      if (this.selectedDate && this.selectedSlot) {
        await this.onDayClick({ id: this.selectedDate, date: this.selectedSlot.time.toJSDate() })
      }

      this.$modal.show('appointmentsAdminModal')
    },
    onDayClick: async function (day) {
      this.selectedDate = day.id
      const date = DateTime.fromJSDate(day.date).toISODate()
      const slots = await this.fetchReservedSlots(date, date)
      this.slots = slots[this.selectedDate]
    },
    fetchReservedSlots: async function (startDate, endDate) {
      const response = await ApiService.fetchReservedSlots(startDate, endDate)
      const days = {}

      if (response.data.slots !== undefined) {
        const data = response.data.slots

        for (const [day, timeslots] of Object.entries(data)) {
          days[day] = {
            date: DateTime.fromISO(day),
            slots: []
          }

          timeslots.forEach((slot, i) => {
            days[day].slots[i] = {}
            days[day].slots[i].available = slot.available
            days[day].slots[i].time = DateTime.fromISO(slot.time)
            days[day].slots[i].total = slot.total
            days[day].slots[i].booked = slot.booked
          })
        }
      }

      return days
    },
    flattenFormFileIds: function (formData) {
      const flattened = []
      formData.files.forEach((fileId) => {
        flattened.push(fileId[0])
      })
      return flattened
    },
    setSelectedPickerSlot: function (slot) {
      this.selectedSlot = slot
      this.selectedDateTime = ''.concat(this.selectedDate, 'T', this.selectedSlot.time.toISOTime({ includeOffset: false }))
    },
    addAppointment: async function (formData) {
      this.formValues.files_upload = null

      const method = this.formValues.id ? 'patch' : 'post'
      const appointment = { ...this.formValues, ...{ slot: this.selectedDateTime }, ...{ privacy_policy: false } }
      const response = await ApiService.saveAppointment(appointment, method)

      if (response.status === 200) {
        this.closeModal()
        notification(method === 'post' ? 'Afspraak succesvol toegevoegd!' : 'Afspraak succesvol aangepast!')
        this.$emit('save')
      }
    }
  },
  watch: {
    open () {
      if (this.open) {
        this.openModal(this.appointment)
      } else {
        this.closeModal()
      }
    }
  },
  computed: {
    images () {
      return this.$store.getters.getImages
    }
  }
}
</script>
